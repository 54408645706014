import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router/dist/vue-router.esm'
import {focus_trap_mixin} from 'javascripts/mixins/focus_trap_mixin.js';
import axios from 'axios';

Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("category-browser")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

    const CategoryBrowser = Vue.component('category-browser', {
      mixins: [focus_trap_mixin],
      data() {
        return {
          show_overlay: false,
          category: null,
          subcategories: [],
          contents: [],
          breadcrumbs: [],
          vue_loaded: false,
          expanded_id: null,
          supplier_href: null
        };
      },
      methods: {
        showModal(href) {
          this.supplier_href = href;

          this.toggleOverlay();
        },
        loadData() {
          let url = "/category_schemes/" + this.$route.params.category_scheme_id + "/categories/" + this.$route.params.category_id + ".json";
          axios.get(url).then((response) => {
            this.category = response.data.category;
            this.siblings = response.data.siblings;
            this.subcategories = response.data.subcategories;
            this.contents = response.data.contents;
            this.breadcrumbs = response.data.breadcrumbs;
          });
        },
        loadCategory(category_id) {
          this.expanded_id = category_id;
          router.push({ path: "/category_schemes/" + this.$route.params.category_scheme_id + "/categories/" + category_id });
        },
        visitExternalLink(url) {
          window.location.href = url;
        }
      },
      created() {
        this.loadData();
      },
      watch: {
        '$route' (to, from) {
          this.loadData();
        }
      }
    })

    Vue.component('leaving_website_modal', {
      props: {
        show_overlay: Boolean,
        toggle_overlay_fn: Function,
        supplier_href: String
      },
      data: function() {
        return {
        };
      },
      methods: {
        closeModal() {
          this.toggle_overlay_fn();
        },
      }
    })

    const router = new VueRouter({
      mode: 'history',
      routes: [
        { path: '/category_schemes/:category_scheme_id/categories/:category_id', component: CategoryBrowser }
      ]
    })

    new Vue({router}).$mount('#category-browser');
  }
});
