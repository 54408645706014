import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start()

import 'stylesheets/hartlepoolnow/application.scss'

import 'javascripts/form_validation'
import 'javascripts/next_events'
import 'javascripts/events'
import 'javascripts/navbar'
import 'javascripts/favourites'
import 'javascripts/organisations'
import 'javascripts/childcare_providers'
import 'javascripts/organisation_finder'
import 'javascripts/weather'
import 'javascripts/cms_pages'
import 'javascripts/category_browser'
import 'javascripts/blog_article_browser'
import 'javascripts/event_browser'
import 'javascripts/site_searches'
import 'javascripts/opportunities'

function ready(callback){
    // in case the document is already rendered
    if (document.readyState!='loading') callback();
    // modern browsers
    else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
    // IE <= 8
    else document.attachEvent('onreadystatechange', function(){
        if (document.readyState=='complete') callback();
    });
  }

// Commented out code - to be reinstated if dropdown for news in nav is used again

// ready(function(){

//     let dropdown = document.querySelector('#navbarSupportedContent .nav-menu .dropdown-toggle')

//     function hideDropdown() {
//       document.querySelector('.dropdown-menu').style.display = 'none';
//       dropdown.setAttribute("aria-expanded", false);
//     }

//     function showDropdown(){
//       document.querySelector('.dropdown-menu').style.display = 'block';
//       dropdown.setAttribute("aria-expanded", true);
//     }

//     // show or hide dropdown for News on click
//     dropdown.addEventListener("click", function() {
//       console.log('click')
//       if(document.querySelector('.dropdown-menu').style.display == 'none') {
//         showDropdown()
//       }
//       else {
//         hideDropdown()
//       }
//     });


//     // If the user presses the "Enter" key on the keyboard
//     dropdown.addEventListener("keypress", function(event) {
//       console.log('keypress')
//       event.preventDefault()
//       if (event.key === "Enter" || event.keyCode == 32) {  // enter or space bar
//         // event.preventDefault();
//         if(document.querySelector('.dropdown-menu').style.display == 'none') {
//           showDropdown()
//         }
//         else {
//           hideDropdown()
//         }
//       }
//     });
//   }
// )

ready(function(){
  MicroModal.init({
    onClose: (modal, activeElement, event) => {
      event.preventDefault();
      event.stopPropagation();
    }
  });
})

ready(function() {
  let externalOpportunityLink = document.querySelector("#opportunityExternalLink");
  if(externalOpportunityLink) {
    externalOpportunityLink.addEventListener("click", function(e) {
      if(typeof ga !== 'undefined') {
        let id = externalOpportunityLink.dataset.opportunityId;
        let name = externalOpportunityLink.dataset.opportunityName;
        let opportunity_id = id + ': ' + name;
        ga('send', 'event', 'Opportunity', 'Click', opportunity_id, {
          'transport': 'beacon'
        });
      }
    });
  }
})
