import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router/dist/vue-router.esm'
import axios from 'axios';
import VueFlatPickr from 'vue-flatpickr-component';
import {pagination} from 'javascripts/mixins/pagination.js';
import {search_form} from 'javascripts/mixins/search_form.js';
import {search_filters} from 'javascripts/mixins/search_filters.js';
import {flatpickr_config} from 'javascripts/mixins/flatpickr_config.js';
import {scroll_to_top} from 'javascripts/mixins/scroll_to_top.js';

import 'flatpickr/dist/flatpickr.css';
Vue.use(VueFlatPickr, {
  name: 'flat-pickr'
});

Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById("childcare-providers-section")) {
    axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

    const ChildcareProviders = Vue.component('childcare_providers', {
      mixins: [scroll_to_top, flatpickr_config, search_form, search_filters, pagination],
      props: [
        'per_page'
      ],
      data: function () {
        return {
          search: {
            model: 'childcare_providers',
            path: 'childcare_providers',
            search_type: 'search_childcare_provider',
            search_url: "/childcare_provider?page=1&per_page=10",
          },
          refreshing_filters: true,
          sort_options: [{"name": "Alphabetical", "parameter": null, "selected": true}],
          is_mobile: window.innerWidth <= 992,
          autocomplete: null,
        };
      },
      methods: {
        triggerUpdateUrl() {
          if (this.search.search_url != this.updateUrl()) {
            this.search.search_url = this.updateUrl();
            router.push({ path: this.search.search_url });
          }
        },
        loadData() {
          this.search.results = [];
          this.triggerUpdateUrl();
          this.show_spinner = true;

          if (this.search.url_search_params != null) {
            this.search.initial_search_submitted = true;
            this.search.search_submitted = true;
          }

          axios.get(`${ this.search.path }.json`, { params: this.search.url_search_params }).then((response) => {
            this.handleResponse(response);
            this.refreshing_filters = false;
          });
        },
        newSearch(refresh_filters) {
          if (refresh_filters) {
            this.refreshing_filters = refresh_filters;
            this.clearCategories();
          }

          this.newSearchSubmitted();
          this.triggerUpdateUrl();
        },
        clearAll() {
          window.location.href = '/childcare_providers';
        },
      },
      computed: {
        documentBody() {
          return document.getElementById('js-body');
        }
      },
      mounted() {
        // Initialise local variables from set params
        this.initialiseFromParams();

        // Make initial call to childcare_providers.json to populate category schemes.
        axios.get(`${ this.search.path }.json`, { params: { per_page: 1 } }).then((response) => {
          this.search.category_schemes = response.data.category_schemes;
        }).then(() => {
          // Now load full data for search
          this.loadData(false);
        });

        const DEFAULT_BOUNDS = new google.maps.LatLngBounds(
          new google.maps.LatLng(54.445319, -1.594018),
          new google.maps.LatLng(54.862270, -1.090714)
        );

        const OPTIONS = {
          bounds: DEFAULT_BOUNDS,
          types: ['geocode'],
          componentRestrictions: {country: 'gb'}
        }

        this.autocomplete = new google.maps.places.Autocomplete(
          (this.$refs.autocomplete),
          OPTIONS
        );

        this.autocomplete.addListener('place_changed', () => {
          let place = this.autocomplete.getPlace();

          if (place.formatted_address) {
            this.search.location = place.formatted_address;
            this.search.latitude = place.geometry.location.lat().toFixed(5);
            this.search.longitude = place.geometry.location.lng().toFixed(5);
          }
        });
      },
      created() {
        this.search.path = window.location.pathname;
        addEventListener('resize', () => {
          this.is_mobile = innerWidth <= 992;
        })
      },
      watch: {
        '$route' (to, from) {
          this.clearSearch();
          this.initialiseFromParams();

          if (to != "/childcare_providers?page=1&per_page=10") {
            this.search.search_submitted = true;
          };
          this.loadData();
        }
      }
    })

    const router = new VueRouter({
      mode: 'history',
      routes: [
        { path: '/childcare_providers/*', component: ChildcareProviders }
      ]
    })

    new Vue({router}).$mount('#childcare-providers-section');
  }
});
