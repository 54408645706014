import favouriteButton from 'javascripts/components/favouriteButton.js';

export default {
  name: 'cmsPageResult',
  components: {
    favouriteButton
  },
  props: {
    cms_page: Object,
    options: Object,
    favourites: {
      type: Array,
      default: () => []
    },
    favourites_enabled: {
      type: Boolean,
      default: false
    }
  },
  template:
  `
    <article
        class="post-item post-item--alt"
    >
        <favourite-button
          v-if="favourites_enabled"
          :favourable_item="cms_page"
          :favourites="favourites"
        ></favourite-button>

        <a
            :href="cms_page.path"
            class="post-item__link"
            :aria-labelledby="cms_page.id"
        >

          {{ cms_page.favourite.page_title }}

        </a>
    </article>
    `
}
