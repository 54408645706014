import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router/dist/vue-router.esm'
import axios from 'axios';
import eventResult from 'javascripts/components/eventResult.js';
import organisationResult from 'javascripts/components/organisationResult.js';
import documentResult from 'javascripts/components/documentResult.js';
import blogArticleResult from 'javascripts/components/blogArticleResult.js';
import cmsPageResult from 'javascripts/components/cmsPageResult.js';
import { focus_trap_mixin } from 'javascripts/mixins/focus_trap_mixin.js';
import { search_form } from 'javascripts/mixins/search_form.js';

Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById("favourites_index")) {
      
      axios.defaults.headers.common['X-CSRF-Token'] = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');
  
      const Favourites = Vue.component('favourites', {
        mixins: [
            search_form,
            focus_trap_mixin
        ],
        props: [
          'per_page'
        ],
        components: {
          eventResult,
          organisationResult,
          documentResult,
          blogArticleResult,
          cmsPageResult
        },
        data: function () {
          return {
            favourites: null,
            event_labels: null,
            blog_articles_options: null,
            favourites_enabled: false,
            favourites_apply_to: [],
            show_filter: true,
            filter_status: "",
            active_video_resource: null,
            search: {
              model: 'favourites',
              path: 'favourites',
              search_url: "/favourites",
              search_type: 'favourites',
            },
            refreshing_filters: true,
            is_mobile: window.innerWidth <= 922,
          };
        },
        methods: {
          setVideoEmbed(resource) {
            this.active_video_resource = resource;
            this.toggleOverlay();
          },
          loadData() {
            this.search.results = [];
            this.show_spinner = true;

            axios.get(`/${ this.search.path }.json`).then((response) => {
              this.handleResponse(response);
              this.refreshing_filters = false;
              this.event_labels = response.data.event_labels;
              this.favourites = this.search.results.map(item => item.id);
              this.favourites_enabled = response.data.favourites_enabled;
              this.favourites_apply_to = response.data.favourites_apply_to;
              this.blog_articles_options = response.data.blog_articles_options;
            });
          },
        },
        computed: {
          favouriteOrgs() {
            if (this.search.results.length) {
              return this.search.results.filter(item => item.klass == 'Organisation');
            } else {
              return []
            }
          },
          favouriteEvents() {
            if (this.search.results.length) {
              return this.search.results.filter(item => item.klass == 'Event');
            } else {
              return []
            }
          },
          favouritePages() {
            if (this.search.results.length) {
              return this.search.results.filter(item => item.klass == 'CmsPage');
            } else {
              return []
            }
          },
          favouriteBlogArticles() {
            if (this.search.results.length) {
              return this.search.results.filter(item => item.klass == 'BlogArticle');
            } else {
              return []
            }
          },
          favouriteDocuments() {
            if (this.search.results.length) {
              return this.search.results.filter(item => item.klass == 'Document');
            } else {
              return []
            }
          }
        },
        created() {
          this.loadData();
        },
      })

      const router = new VueRouter({
        mode: 'history',
        routes: [{
          path: '/favourites/*',
          component: Favourites
        }]
      })
  
      new Vue({
        router
      }).$mount('#favourites_index');
    }
  });
  