export default {
  name: "opportunityResult",
  props: {
    organisation: Object,
  },
  template: `
    <article class="organisation-result">
   
        <a v-if="typeof organisation.result_image !== 'undefined' && organisation.result_image.length" :href="organisation.path">
          <img class="result__image" :src="organisation.result_image" style="margin-bottom: 10px" :alt="organisation.name + ' logo'">
        </a>

        <header class="organisation-result__header">
            <div class="organisation-result__title-wrapper">
                <h1 class="organisation-result__title">
                    <a :href="organisation.path" class="organisation-result__title-link">
                        {{ organisation.name }}
                    </a>
                </h1>
            </div>
        </header>

        <div class="organisation-result__body">
            <p class="organisation-result__content" v-if="organisation.description">
                {{ organisation.description.substring(0,140) }}

                <span>{{organisation.description.length > 140 ? '...' : ''}}</span>
            </p>
            <p class="organisation-result__content" v-else-if="organisation.short_description">
                {{ organisation.short_description.substring(0,140) }}

                <span>{{organisation.short_description.length > 140 ? '...' : ''}}</span>
            </p>

            <div class="organisation-result__contact">
                <div v-if="organisation.provider" class="organisation-result__contact-link">
                    <i class="fa fa-building mr-2" aria-hidden="true"></i>
                    <span class="organisation-result__contact-text">{{ organisation.provider }}</span>
                </div>
                <i class="fas fa-clock mr-2" aria-hidden="true"></i>
                <span v-if="organisation.closing_date" class="organisation-result__contact-text">Closes on {{ organisation.closing_date }}</span>
                <span v-else class="organisation-result__contact-text">No closing date</span>
                <div v-if="organisation.industry" class="organisation-result__contact-link">
                    <i class="fas fa-briefcase mr-2" aria-hidden="true"></i>
                    <span class="organisation-result__contact-text">{{ organisation.industry }}</span>
                </div>

                <div v-if="organisation.location" class="organisation-result__contact-link">
                    <i class="fas fa-map-marker mr-2" aria-hidden="true"></i>
                    <span class="organisation-result__contact-text">{{ organisation.location }}</span>
                </div>

                <div v-if="organisation.weekly_wage && organisation.weekly_wage != '£Unknown'" class="organisation-result__contact-link">
                    <i class="fas fa-wallet mr-2" aria-hidden="true"></i>
                    <span class="organisation-result__contact-text">{{ organisation.weekly_wage }}</span>
                </div>

                <div class="post-item__distance-miles" v-if="organisation.distance_in_miles >= 0">
                    About {{ organisation.distance_in_miles }} miles away
                </div>

            </div>
        </div>

        <a :href="organisation.path" class="organisation-result__cta" :aria-label="'View ' + organisation.name + ' details'">
            <span class="organisation-result__cta-text">Details</span>
        </a>
    </article>
  `,
};
